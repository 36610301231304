import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
require('./src/assets/css/transitions.css')
require('./src/assets/css/slick.css')
require('./src/assets/css/master.css')
require('./src/assets/css/responsive.css')
require('./src/assets/css/ionicons.min.css')
require('./src/assets/css/custom-icons.min.css')
require('./src/assets/css/cubeportfolio.css')
require('./src/assets/css/bootsnav.css')
require('./src/assets/css/animate.css')
